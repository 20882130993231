<template>
  <div class="arrange_seats">
    <div class="top_card card">
      <div class="storeCenter_item">
        <div class="storeCenter_item_top">
          <!-- 卡片左边 -->
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="topDetails.surfaceDrawing" alt="" />
              <!-- v-if="item.activityImg" -->
              <!-- <img v-else src="../../assets/image/Group 3237.png" alt="" /> -->
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div class="title_row">
                <div class="title">
                  {{ topDetails.activityName }}
                </div>
                <div class="title_row_item">
                  <img
                    v-if="topDetails.participationMode === '1'"
                    src="@/assets/image/on-line.png"
                    alt=""
                  />
                  <img v-else :src="require('@/assets/image/off-line.png')" alt="" />
                  <span class="mode_txt">{{
                    topDetails.participationMode === '1' ? '线上' : '线下'
                  }}</span>
                </div>
                <div class="state_box" :class="getActivityState(topDetails, 'class')">
                  <img
                    v-if="topDetails.activityState === '4'"
                    :src="require('@/assets/image/activity-state01.png')"
                    alt=""
                  />
                  <span>{{ getActivityState(topDetails, 'txt') }}</span>
                </div>
              </div>
              <!-- 活动主题 -->
              <div class="center_row">{{ topDetails.caseSubject }}</div>
              <div class="address">
                <div>
                  <span>所在地区：</span>
                  <span v-if="topDetails.activityProvince">
                    {{ topDetails.activityProvince }}
                    <span>-</span>
                    <span>{{ topDetails.activityCity ? topDetails.activityCity : '暂无' }}</span>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div v-if="topDetails.activityPhase === '1'">
                  <div v-if="topDetails.activityStartTime">
                    <span>活动时间：</span>
                    <span> {{ topDetails.activityStartTime }}</span>
                    <span> 至 </span>
                    <span>{{ topDetails.activityEndTime }}</span>
                  </div>
                  <div v-else>
                    <span>活动时间：暂无</span>
                  </div>
                </div>
                <div v-else>
                  <span>活动时间：</span>
                  <span>
                    {{
                      topDetails.activityProjectTime ? topDetails.activityProjectTime : '暂无'
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card body_card">
      <div class="page_one" v-if="!isSet">
        <img :src="require('@/assets/image/seat/not-set-seat.png')" alt="" />
        <div class="txt_row">设置场地座位，更快捷安排座位</div>
        <el-button type="primary" size="middle" @click="setManySeatsBtn">去设置</el-button>
      </div>
      <div class="page_two" v-else>
        <div class="row">
          <el-upload
            action
            list-type="xlsx,xls"
            :http-request="upload"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            v-loading="uploading"
          >
            <el-button type="primary">批量导入</el-button>
          </el-upload>
          <el-button @click="editManySeatsBtn" style="margin-left: 10px">编辑座位</el-button>
          <div class="color5">
            <span>总座位（个）{{ allSeatNum }}</span>
            <span
              >【{{ `${accountForm.rowNumber + '排' + accountForm.columnsNumber + '列'}` }}】</span
            >
            <span>已安排座位：{{ accountForm.reservedSeatNumber }}</span>
            <span>未安排座位：{{ accountForm.unseatedNumber }}</span>
          </div>
        </div>
        <div class="page_two_content">
          <div class="first_box">主持台</div>
          <div class="row_list">
            <div class="row_seats_box" v-for="(item, index) in seatRowVos" :key="index">
              <!-- 循环展示出每一排 row -->
              <div class="row_seats_box_index">{{ index + 1 }}</div>
              <div
                class="seat_item"
                v-for="(i1, i2) in item.seatRowVos"
                :key="i2"
                @click="setSeatBtn(i1)"
              >
                <!-- 循环出每一排的座位 col -->
                <div v-if="!i1.userActivity">
                  <!-- 座位没有安排人员 -->
                  <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content">{{ i1.rowNumber + '排' + i1.columnsNumber + '列' }}</div>
                    <div class="seat_img_box">
                      <img :src="require('@/assets/image/seat/seat-not-active.png')" alt="" />
                    </div>
                  </el-tooltip>
                </div>
                <div v-else>
                  <div class="seat_item">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">{{ i1.rowNumber + '排' + i1.columnsNumber + '列' }}</div>
                      <div class="seat_img_box">
                        <img :src="require('@/assets/image/seat/seat-is-active.png')" alt="" />
                      </div>
                    </el-tooltip>

                    <div class="seat_item_row color5">{{ i1.realName }}</div>
                    <el-tooltip
                      :content="i1.chamberCommerceName"
                      effect="dark"
                      popper-class="tool_tip"
                      placement="top"
                    >
                      <div class="seat_item_row color5 hide_txt">
                        {{ i1.chamberCommerceName }}
                      </div>
                    </el-tooltip>
                    <div class="seat_item_row color5">
                      {{ i1.position }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="details_foot_btn_list">
          <el-button type="primary" @click="saveBtn">保存</el-button>
          <el-button class="cancel" @click="goBack">返回上一级</el-button>
        </div>
      </div>
    </div>

    <!-- 设置座次弹窗 总 -->
    <el-dialog title="设置场地座位" :visible.sync="dialogVisiable.setManySeats" width="40%">
      <div class="dialog_container">
        <div class="storeCenter_item_top">
          <!-- 卡片左边 -->
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="topDetails.surfaceDrawing" alt="" />
              <!-- v-if="item.activityImg" -->
              <!-- <img v-else src="../../assets/image/Group 3237.png" alt="" /> -->
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div class="title_row">
                <div class="title">
                  {{ topDetails.activityName }}
                </div>
                <div class="title_row_item">
                  <img
                    v-if="topDetails.participationMode === '1'"
                    src="@/assets/image/on-line.png"
                    alt=""
                  />
                  <img v-else :src="require('@/assets/image/off-line.png')" alt="" />
                  <span class="mode_txt">{{
                    topDetails.participationMode === '1' ? '线上' : '线下'
                  }}</span>
                </div>
                <div class="state_box" :class="getActivityState(topDetails, 'class')">
                  <img
                    v-if="topDetails.activityState === '4'"
                    :src="require('@/assets/image/activity-state01.png')"
                    alt=""
                  />
                  <span>{{ getActivityState(topDetails, 'txt') }}</span>
                </div>
              </div>
              <!-- 活动主题 -->
              <div class="center_row">{{ topDetails.caseSubject }}</div>
              <div class="address">
                <div>
                  <span>所在地区：</span>
                  <span v-if="topDetails.activityProvince">
                    {{ topDetails.activityProvince }}
                    <span>-</span>
                    <span>{{ topDetails.activityCity ? topDetails.activityCity : '暂无' }}</span>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div v-if="topDetails.activityPhase === '1'">
                  <div v-if="topDetails.activityStartTime">
                    <span>活动时间：</span>
                    <span> {{ topDetails.activityStartTime }}</span>
                    <span> 至 </span>
                    <span>{{ topDetails.activityEndTime }}</span>
                  </div>
                  <div v-else>
                    <span>活动时间：暂无</span>
                  </div>
                </div>
                <div v-else>
                  <span>活动时间：</span>
                  <span>
                    {{
                      topDetails.activityProjectTime ? topDetails.activityProjectTime : '暂无'
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row account_row color5">
          当前报名成功用户（人）：{{ topDetails.registeredNumber }}
        </div>
        <div class="row">
          <div class="label">总座位</div>
          <div class="all_seat_num middle-input">{{ allSeatNum }}</div>
          <div class="unit_txt">个</div>
        </div>
        <div class="row">
          <div class="label">总排数</div>
          <el-input
            class="middle-input"
            v-model.number="accountForm.rowNumber"
            oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
            placeholder="请输入总排数"
            @change="getAllSeats"
          ></el-input>
        </div>
        <div class="row">
          <div class="label">总列数</div>
          <el-input
            class="middle-input"
            v-model.number="accountForm.columnsNumber"
            oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
            placeholder="请输入总列数"
            @change="getAllSeats"
          ></el-input>
        </div>
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="saveSetManySeats">保存</el-button>
        <el-button @click="dialogVisiable.setManySeats = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 设置座位弹窗 单个 -->
    <el-dialog title="设置场地座位" :visible.sync="dialogVisiable.setOneSeat" width="60%">
      <div class="dialog_container">
        <div class="top_box" v-if="seatDialogUserInfo.isShow">
          <div class="row">
            <div class="img_box">
              <img :src="require('@/assets/image/seat/seat-is-active.png')" alt="" />
            </div>
            <div class="color5 middle_size">
              {{ seatForm.rowNumber + '排' + seatForm.columnsNumber + '列' }}
            </div>
          </div>
          <div class="row">
            <div class="head_img_box">
              <img :src="seatDialogUserInfo.headImgUrl" alt="" />
            </div>
            <div class="row_right">
              <div class="row">{{ seatDialogUserInfo.realName }}</div>
              <div class="info_row">
                <span>{{ seatDialogUserInfo.chamberCommerceName }}</span>
                <span class="color5">|</span>
                <span>{{ seatDialogUserInfo.position }}</span>
                <span class="color5">|</span>
                <span>{{ seatDialogUserInfo.phone }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="search_row">
          <el-input
            class="search_input"
            placeholder="请输入姓名、联系电话、商会名称"
            v-model="queryInfo.queryCondition"
            clearable
            @clear="searchUserList"
            @keyup.enter.native="searchUserList"
          >
            <el-button class="search_btn" slot="append" @click="searchUserList">搜索</el-button>
          </el-input>
          <el-checkbox v-model="queryInfo.isSeat" @change="searchUserList"
            >只查看未配置座位用户</el-checkbox
          >
        </div>
        <div class="in_table">
          <el-table
            v-loading="loading"
            :data="userList"
            stripe
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="头像" align="center">
              <template v-slot="{ row }">
                <img :src="row.headImgUrl" width="42px" height="42px" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="真实姓名" prop="realName" align="center"></el-table-column>
            <el-table-column
              label="所属商会"
              prop="chamberCommerceName"
              align="center"
            ></el-table-column>
            <el-table-column label="商会职务" prop="position" align="center"></el-table-column>
            <el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
            <el-table-column label="票种" align="center" prop="priceName"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <span class="span_btn" @click="configOneSeat(row)">
                  {{ row.isSeat ? '取消配置' : '配置' }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button class="cancel" @click="dialogVisiable.setOneSeat = false">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { accMul } from '@/utils/function.js'
import {
  selectById,
  selectManySeats,
  addSetSeats,
  updateOneSeat,
  selectSeatsList,
  importSeat,
  selectSignUp
} from '@/api/activecenter'
export default {
  name: 'arrangeSeats',
  data() {
    return {
      messageCount: 0, // 消息弹出次数
      uploading: false, // 上传文件loading
      seatId: null, // 座位id
      isSet: false, // 是否设置了座次
      echo: 0, // 0为新增座次排列，1为编辑
      requestNum: 0, // 节流阀
      // 查询报名用户列表
      queryInfo: {
        activityId: null,
        isSeat: false,
        pageNum: 1,
        pageSize: 10,
        queryCondition: ''
      },
      loading: false,
      userList: [], // 已报名用户列表
      total: 0, // 总条数
      // 设置总座位数传参
      accountForm: {
        activityId: null,
        rowNumber: 0, // 总排数
        columnsNumber: 0, // 总列数
        reservedSeatNumber: 0, // 已安排座位
        unseatedNumber: 0 // 未安排座位
      },
      seatDialogUserInfo: {
        isShow: false, // 是否显示用户信息
        realName: '', // 姓名
        chamberCommerceName: '', // 商会名称
        position: '', // 商会职位
        headImgUrl: '', // 头像
        phone: '' // 手机号
      },
      seatForm: {}, // 单个座位安排传参
      seatRowVos: [], // 座位安排情况
      allSeatNum: 0, // 总座位数
      dialogVisiable: {
        setManySeats: false, // 总座位数弹窗
        setOneSeat: false // 设置单个座位弹窗
      },
      activityId: null,
      topDetails: {} //头部活动信息
    }
  },
  computed: {
    // 获取活动状态
    getActivityState() {
      return function (item, type) {
        const state = item.activityState
        if (type === 'txt') {
          if (item.activityPhase === '2') {
            return '规划中'
          } else {
            if (state === '1') {
              return '未开始'
            } else if (state === '2') {
              return '进行中'
            } else if (state === '3') {
              return '已结束'
            } else if (state === '4') {
              return '预热中'
            }
          }
        } else {
          if (item.activityPhase === '2') {
            return 'state_five'
          } else {
            if (state === '1') {
              return 'state_one'
            } else if (state === '2') {
              return 'state_two'
            } else if (state === '3') {
              return 'state_three'
            } else if (state === '4') {
              return 'state_four'
            }
          }
        }
      }
    }
  },
  created() {
    this.activityId = Number(this.$route.query.id)
    this.queryInfo.activityId = this.activityId
    this.accountForm.activityId = this.activityId
    this.getSelectById()
    this.searchSeatCount()
  },
  methods: {
    //活动详情
    async getSelectById() {
      const { data: res } = await selectById({ id: this.activityId })
      if (res.resultCode == 200) {
        this.topDetails = res.data
      }
    },
    // 打开设置总座位数弹窗 新增
    setManySeatsBtn() {
      this.echo = 0
      this.dialogVisiable.setManySeats = true
    },
    // 打开设置总座位数弹窗 编辑
    async editManySeatsBtn() {
      this.dialogVisiable.setManySeats = true
      this.echo = 1
    },
    // 查询总座位数 查数量
    async searchSeatCount() {
      const { data: res } = await selectManySeats({ activityId: this.activityId })
      if (res.resultCode === 200) {
        if (res.data) {
          this.accountForm = res.data
          this.accountForm.activityId = this.activityId
          this.searchSeat()
          this.getAllSeats()
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 查询座位排列情况 查座次排列
    async searchSeat() {
      const { data: res } = await selectSeatsList({ activityId: this.activityId })
      if (res.resultCode === 200) {
        this.seatRowVos = res.data.seatRowVos
      } else {
        this.$message.error(res.message)
      }
    },
    // 计算总座位数
    getAllSeats() {
      const row = this.accountForm.rowNumber
      const col = this.accountForm.columnsNumber
      this.allSeatNum = accMul(row, col)
      if (this.allSeatNum > 0) {
        this.isSet = true
      } else {
        this.isSet = false
      }
    },
    // 分页 座位弹窗
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.searchSeat()
      // console.log(`每页 ${val} 条`)
    },
    // 分页 座位弹窗
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.searchSeat()
      // console.log(`当前页: ${val}`)
    },
    // 保存 生成座位数弹窗
    async saveSetManySeats() {
      if (this.accountForm.columnsNumber === 0) {
        this.$message.warning('请设置座位列数~')
      } else if (this.accountForm.rowNumber === 0) {
        this.$message.warning('请设置座位排数~')
      } else {
        this.$message.warning('座位生成中，请稍等~')
        this.accountForm.activityId = this.activityId
        const { data: res } = await addSetSeats(this.accountForm)
        if (res.resultCode === 200) {
          // this.getAllSeats()
          // this.searchSeat()
          this.searchSeatCount()
          setTimeout(() => {
            this.dialogVisiable.setManySeats = false
          }, 1000)
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 上传座位Excel
    async upload(file) {
      // console.log(file.file)
      const form = new FormData()
      form.append('activityId', this.activityId)
      form.append('excelFile', file.file)
      const { data: res } = await importSeat(form)
      if (res.resultCode === 200) {
        this.uploading = true
        this.$message.success('批量导入座位成功!')
        this.searchSeat()
      } else {
        this.$message.error(res.message)
      }
    },
    // 上传完成前
    beforeUpload(file) {
      this.uploading = true //上传时显示
      // this.form.size = file.size
      // var fileSize = file.size / 1024 / 1024 < 100
      this.$message('正在上传中，请稍等')
    },
    //上传成功回调
    handleSuccess(res, file) {
      console.log('上传成功~')
      //后台上传地址
      this.uploading = false //无论是否成功懒加载都隐藏
      // if (res.resultCode == 0) {
      //   this.$message.success('上传成功')
      //   this.form.path = res.resultData
      //   console.log(res.resultData)
      // } else {
      //   this.$message.error('上传失败，请重新上传')
      // }
    },
    // 配置单个座位 弹窗
    setSeatBtn(i) {
      this.seatDialogUserInfo = {
        isShow: false, // 判断是否展示
        realName: i.realName, // 姓名
        chamberCommerceName: i.chamberCommerceName, // 商会名称
        position: i.position, // 商会职位
        headImgUrl: i.headImgUrl, // 头像
        phone: i.phone // 手机号
      }
      if (i.userActivity) {
        this.seatDialogUserInfo.isShow = true
      } else {
        this.seatDialogUserInfo.isShow = false
      }
      this.seatId = i.id
      this.seatForm = {
        activityId: this.activityId,
        columnsNumber: i.columnsNumber,
        id: null,
        rowNumber: i.rowNumber,
        userActivity: i.userActivity
      }

      this.dialogVisiable.setOneSeat = true
      this.loading = true
      this.searchUserList()
    },
    // 查询报名成功用户
    async searchUserList() {
      const { data: res } = await selectSignUp(this.queryInfo)
      if (res.resultCode === 200) {
        this.userList = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error(res.message)
      }
    },
    // 配置单个座位
    async configOneSeat(row) {
      const txt = row.isSeat ? '取消配置' : '配置'
      this.seatForm.id = row.isSeat ? row.seatId : this.seatId
      this.seatForm.userActivity = row.isSeat ? null : row.userActivity
      const { data: res } = await updateOneSeat(this.seatForm)
      if (res.resultCode === 200) {
        this.$message.success(txt + '成功!')
        if (!row.isSeat) {
          // 如果此次操作是配置座位
          this.seatDialogUserInfo = {
            isShow: true,
            realName: row.realName, // 姓名
            chamberCommerceName: row.chamberCommerceName, // 商会名称
            position: row.position, // 商会职位
            headImgUrl: row.headImgUrl, // 头像
            phone: row.phone // 手机号
          }
        } else {
          if (this.seatDialogUserInfo.phone === row.phone) {
            this.seatDialogUserInfo.isShow = false
          }
        }
        // 成功后调查用户列表接口刷新数据
        this.searchSeat()
        this.searchUserList()
        this.searchSeatCount()
      } else {
        this.$message.error(txt + '失败!')
      }
    },
    // 重置消息弹出次数
    resetMessageCount() {
      this.messageCount++
      setTimeout(() => {
        this.messageCount = 0
      }, 2000)
    },
    // 保存按钮
    async saveBtn() {
      if (this.messageCount === 0) {
        this.resetMessageCount()
        this.$message.success('保存成功!')
      }
    },
    // 返回按钮
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.arrange_seats {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  ::v-deep .cancel {
    color: #448aff;
    border: #448aff 1px solid;
    background-color: #fff;
  }
  .middle_size {
    font-size: 16px;
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .card {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
  }
  .top_card {
    margin-bottom: 20px;
  }

  .body_card {
    flex: 1;
    display: flex;
    // flex-flow: column;
    .page_one,
    .page_two {
      display: flex;
      flex-flow: column;
    }
    .page_one {
      margin: auto;
      align-items: center;
      img {
        width: 190px;
        height: 190px;
        margin-bottom: 10px;
      }
      .txt_row {
        font-size: 20px;
        line-height: 28px;
        color: #263245;
        margin-bottom: 20px;
      }
      ::v-deep .el-button {
        color: #fff;
        background-color: #3562ff;
      }
    }
    .page_two {
      width: 100%;
      height: 100%;

      .row {
        width: 100%;
        align-items: flex-end;
        margin-bottom: 10px;
        span {
          margin-left: 20px;
        }
      }
      .page_two_content {
        max-width: 100%;
        max-height: 56vh;
        overflow-x: auto;
        overflow-y: auto;
        /* 滚动条整体部分 */
        &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
        .first_box {
          width: 100%;
          height: 72px;
          border-radius: 8px;
          background-color: #f9f9f9;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #333;
          margin-bottom: 10px;
        }
        .row_list {
          .row_seats_box {
            width: 100%;
            display: flex;
            margin-bottom: 10px;
            padding: 10px;
            padding-top: 20px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: #f9f9f9;
            .row_seats_box_index {
              font-size: 18px;
              line-height: 46px;
              color: #000;
              margin-right: 20px;
            }
            .seat_item {
              cursor: pointer;
              min-width: 80px;
              max-width: 80px;
              padding: 0 10px;
              display: flex;
              flex-flow: column;
              align-items: center;
              // margin-right: 20px;
              .seat_img_box {
                width: 46px;
                height: 46px;
                margin-bottom: 10px;
                img {
                  width: 46px;
                  height: 46px;
                }
              }
              .seat_item_row {
                margin-bottom: 10px;
              }
              .hide_txt {
                width: 100px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              // .hide_txt:hover {
              //   width: fit-content;
              //   word-break: break-all
              // }
            }
          }
        }
      }
      .details_foot_btn_list {
        margin-top: auto;
        padding-top: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        border-top: #f4f4f4 1px solid;
      }
    }
  }
  .dialog_container {
    width: 100%;
    display: flex;
    flex-flow: column;
    .top_box {
      width: 100%;
      background-color: #f8f9fb;
      margin-bottom: 10px;
      padding: 10px 0 0 10px;
      box-sizing: border-box;
      .row {
        align-items: center;
        line-height: 24px;
        margin-bottom: 10px;
        .img_box {
          width: 20px;
          height: 20px;
          margin-right: 16px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .info_row span {
        margin-right: 10px;
      }
      .head_img_box {
        width: 44px;
        height: 44px;
        margin-right: 16px;
        img {
          width: 44px;
          height: 44px;
        }
      }
    }
    .span_btn {
      cursor: pointer;
      color: #4e93fb;
    }
    .search_row {
      width: 100%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      // line-height: 40px;
      .search_input {
        width: 380px;
        margin-right: 16px;
        .search_btn {
          background-color: #448aff;
          color: #fff;
          border-radius: 0;
          border: 1px solid #448aff;
        }
      }
    }
    .account_row {
      padding: 10px 0;
      margin-top: 10px;
    }
    .row {
      line-height: 40px;
      margin-bottom: 10px;
      .label {
        font-size: 14px;
        color: #333;
        margin-right: 16px;
      }
      .all_seat_num {
        background-color: #fafafa;
        line-height: 40px;
        padding-left: 16px;
        box-sizing: border-box;
      }
      .unit_txt {
        color: #c9c9c9;
        margin-left: 8px;
      }
    }
    .in_table {
      height: 40vh;
      overflow-y: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
    }
  }
  .storeCenter_item {
    width: 100%;
    // margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
  }
  .storeCenter_item_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    // padding: 16px 20px;
    .storeCenter_item_top_left {
      display: flex;
      align-items: center;
      .banner_box {
        width: 76px;
        height: 76px;
        margin-right: 16px;
        img {
          width: 76px;
          height: 76px;
        }
      }

      .storeCenter_item_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title_row {
          display: flex;
          align-items: center;
          line-height: 24px;
          .title {
            font-family: 'PingFang SC';
            font-size: 18px;
            color: #151515;
            margin-right: 18px;
          }
          .title_row_item {
            display: flex;
            align-items: center;
            margin-right: 18px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            .mode_txt {
              font-size: 14px;
              color: #6e6e6e;
            }
          }
          .state_box {
            display: flex;
            justify-content: center;
            min-width: 50px;
            padding: 0 10px;
            height: 18px;
            font-size: 14px;
            line-height: 18px;
            border-radius: 18px;
            // background-color: #999999;
            text-align: center;
            overflow: hidden;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .state_one {
            background-color: #f3efeb;
            color: #c2946b;
          }
          .state_two {
            background-color: #fff3e9;
            color: #ff8a21;
          }
          .state_three {
            background-color: #f5f5f5;
            color: #9b9a9a;
          }
          .state_four {
            background-color: #fff3e9;
            color: #ff8a21;
          }
          .state_five {
            background-color: #f9f4f0;
            color: #c2946b;
          }
        }
        .center_row {
          margin: 10px 0;
        }
        .address {
          display: flex;
          font-size: 14px;
          div {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
</style>
